<template>
    <div class="forgot-password">
        <b-container>
            <b-form class="form" @submit.prevent="forgotPassword()">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="logo"
                    alt=" "
                />
                <h4 class="question">
                    {{ $t('forgotPassword.question') }}
                </h4>
                <h5 class="guideline">
                    {{ $t('forgotPassword.guideline') }}
                </h5>
                <b-form-input
                    v-model="email"
                    type="email"
                    required
                    placeholder="E-MAIL"
                    class="input-email"
                />
                <div class="wrong-text mt-3">
                    <span v-if="errorMessage">
                        {{ errorMessage }} <span v-if="expirationTime">{{ expirationTime }} {{ $t('forgotPassword.minutes') }}</span>
                    </span>
                </div>
                <b-button
                    type="submit"
                    variant="primary"
                    :disabled="!email"
                    class="button"
                >
                    {{ $t('forgotPassword.buttonText') }}
                </b-button>
                <b-container v-if="emailWasSent" class="mt-3">
                    {{ $t('forgotPassword.emailWasSent') }}
                </b-container>
                <router-link to="/login" class="back">
                    {{ $t('forgotPassword.return') }}
                </router-link>
            </b-form>
        </b-container>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import passwordService from '@/api/password'
    export default {
        mixins: [ config ],
        data() {
            return {
                email: null,
                emailWasSent: false,
                failedResponse: {
                    RESET_PASSWORD_USER_NOT_FOUND: this.$t('forgotPassword.userNotFound'),
                    RESET_PASSWORD_USER_NOT_ENABLED: this.$t('forgotPassword.userNotEnabled'),
                    RESET_PASSWORD_ACTIVE_RESET_REQUEST: this.$t('forgotPassword.alreadyRequested')
                },
                expirationTime: null,
                errorMessage: null
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            }
        },
        methods: {
            forgotPassword() {
                this.emailWasSent = false
                passwordService.passwordReset(this.email).then(() => {
                    this.emailWasSent = true
                    this.email = null
                }, (error) => {
                    this.expirationTime = null
                    const errorEnum = error.response?.data?.code

                    if (errorEnum === 'RESET_PASSWORD_ACTIVE_RESET_REQUEST') {
                        this.expirationTime = error.response?.data?.tryAgainAfterMinutes
                    }
                    this.errorMessage = this.failedResponse[errorEnum]
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.forgot-password {
    padding-top: 80px;
    height: 100%;
    margin: 0 auto;
    min-width: 300px;
    overflow: auto;
    padding-bottom: 2.5rem;
}

.form {
    max-width: 420px;
    padding: 30px 38px 46px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid var(--white);
    background-color: var(--login-forms-background);
    color: var(--white);
}

.wrong-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--form-error-color);
}

.input-email {
    font-size: 16px;
    height: auto;
    padding: 10px;
    font-weight: 700;
    margin-top: 10%;
}

.logo {
    margin-top: 10%;
    width: 191px;
}

.button {
    padding: 4% 27%;
    white-space: nowrap;
    border-radius: 17px;
    border: 1px solid var(--white);
    background-color: var(--white);
    display: block;
    width: 100%;
    margin-top: 50px;
    color: var(--login-forms-background);
    font-weight: 700;
    font-size: 18px;
}

.question {
    margin-top: 10%;
}

.guideline {
    margin-top: 10%;
}

.back {
    display: block;
    margin-top: 0.75rem;
    color: var(--white);
    text-decoration: none;
}

</style>
