import restApi from '@/utils/rest-api'

export default {
    passwordReset: (email) => {
        return restApi.post('players/password-reset', { email })
    },
    newPassword: (password, resetToken) => {
        return restApi.put('players/password', { password, resetToken })
    },
    invitationRegister: ({ password, acceptedAllTerms }, inviteCode) => {
        return restApi.post('players/register/invitation',
            {
                invitationCode: inviteCode,
                acceptedAllTerms,
                password
            })
    },
    register({ email, password, acceptedAllTerms
    }) {
        return restApi.post('players/register', {
            email,
            password,
            acceptedAllTerms
        })
    },
    tokenLogin: (confirmationCode) => {
        return restApi.post('players/token-login', { code: confirmationCode })
    }
}
